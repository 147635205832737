<template>
    <div class="row">
        <div class="col-md-6">
            <empresa-datos-datos :empresa="empresa" :id="id" />
        </div>
        <div class="col-md-5" v-if="(id > 0)">
            <Empresa-datos-contabilidad :empresa="empresa" :id="id" />
            <Empresa-datos-facturacion :empresa="empresa" :id="id" />
        </div>
    </div>
</template>
<script>
import EmpresaDatosDatos from './EmpresaDatosDatos.vue'
import EmpresaDatosContabilidad from './EmpresaDatosContabilidad.vue'
import EmpresaDatosFacturacion from './EmpresaDatosFacturacion.vue'
export default {
    props: ['empresa', 'id'],
    components: {
        'empresa-datos-datos': EmpresaDatosDatos,
        'Empresa-datos-contabilidad': EmpresaDatosContabilidad,
        'Empresa-datos-facturacion':EmpresaDatosFacturacion,
    },
    data() {
        return {}
    }
}
</script>