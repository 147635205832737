<template>
    <seccion-datos :titulo="facturacion" tipo="danger" @cambios="guardar(id)">                    
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.clave') }}</label>
                    <input v-model="clave_certificado" type="password" class="form-control nombre_completo dato" id="nombre_completo">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.certificadodigital') }}</label>
                    <input type="file" class="form-control nombre_completo dato">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-12">
                    <p class="text-yellow">{{ $t('general.descripcionclave') }}</p>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id'],
    data() {
        return {
            empresa: '',
            ruta_certificado: '',
            clave_certificado: '',
            seleccionado: '',
            facturacion: '',
        }
    },
    methods: {
        async obtenerEmpresa(id) {
            const api = new PwgsApi;
            const datos = await api.get('empresas/' + id);
            this.empresa = datos.datos;
            this.$store.dispatch('cambiarCargando', true);
            this.ruta_certificado = this.empresa.ruta_certificado;
            this.clave_certificado = this.empresa.clave_certificado;
            this.$store.dispatch('cambiarCargando', false);
            this.facturacion = this.$t('general.facturacion');
        },
        async guardar(id) {
            /* put  modulos/pwgsapi/index.php/lineas-facturacion/:id   */
            const subidadatos = { ruta_certificado: this.ruta_certificado, clave_certificado: this.clave_certificado};
            const api = new PwgsApi;
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('empresas/' + this.id, subidadatos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.obtenerEmpresa(id);
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/lineas-facturacion/  */
                try {
                    let res;
                    res = await api.post('empresas/', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/empresas/' + res.id);
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
    },
    mounted() {

        this.obtenerEmpresa(this.id);
    },
    watch: {
        id() {
            this.obtenerEmpresa(this.id);
        }
    }
}
</script>