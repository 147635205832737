<template>
    <div class="row">
        <div class="col-md-12">
            <seccion-datos :titulo="datosempresa" @cambios="guardar(id)">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <!-- No podrá editarse este campo, solo en la creación -->
                            <div class="form-group">
                                <label>{{ $t('general.empresa') }}&nbsp;</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="nombre_empresa">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="nombre_completo">{{ $t('general.nombrecomercial') }}</label>
                            <input type="text" class="form-control nombre_completo dato" v-model="nombre_comercial">
                        </div>
                    </div>
                </div>
                    <div class="form-group">
                                <div class="row">
                                    <div class="col-md-2">
                                        <label >NIF</label>
                                        <select v-model="tipo_identificacion" class="form-control select">
                                            <option value="N.I.F.">N.I.F.</option>
                                            <option value="C.I.F.">C.I.F.</option>
                                            <option value="N.I.E.">N.I.E.</option>
                                            <option value="OTROS">{{ $t('general.otros') }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-4">
                                         <label >&nbsp;</label>
                                        <input type="text" v-model="cif_empresa" class="form-control nombre_completo dato"  >
                                    </div>
                                    <div class="col-md-6">
                                        <label>Tipo de Persona</label>
                                            <select v-model="tipo_persona_empresa" class="form-control select">
                                                <option value="F">{{ $t('general.fisica') }}</option>
                                                <option value="J">{{ $t('general.juridica') }}</option>
                                            </select>
                                        </div>
                                    </div>                                 
                                </div>         
                    <div class="row">
                        <div class="col-md-9">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.direccion') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="direccion_empresa">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.cpostal') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="codigo_postal_empresa">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.ciudad') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="ciudad_empresa">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.provincia') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="provincia_empresa">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.telefono') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="telefono_empresa">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.codigopais') }}</label>
                                <input type="text" class="form-control nombre_completo dato" v-model="codigo_pais_empresa">
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="form-group">
                                <label for="nombre_completo">Email</label>
                                <input type="text" class="form-control nombre_completo dato" placeholder="">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.tipoderesidencia') }}</label>
                                <select v-model="tipo_residencia_empresa" class="form-control select">
                                    <option value="R">{{ $t('general.residente') }}</option>
                                    <option value="U">{{ $t('general.unioneuropea') }}</option>
                                    <option value="E">{{ $t('general.extranjero') }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="nombre_completo">{{ $t('general.logotipo') }}</label>
                                <input type="file" class="form-control" placeholder="">
                            </div>
                        </div>
                    </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id'],
    data() {
        return {
            empresa:'',
            nombre_empresa: '',
            nombre_comercial: '',
            direccion_empresa: '',
            codigo_postal_empresa: '',
            ciudad_empresa: '',
            provincia_empresa: '',
            telefono_empresa: '',
            cif_empresa: '',
            logo: '',
            tipo_residencia_empresa: '',
            tipo_persona_empresa: '',
            codigo_pais_empresa: '',
            nombre_fisica_empresa: '',
            apellido1_fisica_empresa: '',
            apellidos2_fisica_empresa: '',
            tipo_identificacion: '',
            protecciondatos_empresa: '',
            gestion_adjuntos_email: '',            
            seleccionado: '',
            datosempresa: "Datos Empresa",
        }
    },
    methods: {

        async obtenerEmpresa(id) {
            const api = new PwgsApi;
            const datos = await api.get('empresas/' + id);
            this.empresa = datos.datos;
            this.$store.dispatch('cambiarCargando', true);
            this.nombre_empresa = this.empresa.nombre_empresa;
            this.nombre_comercial = this.empresa.nombre_comercial;
            this.direccion_empresa = this.empresa.direccion_empresa;
            this.codigo_postal_empresa = this.empresa.codigo_postal_empresa;
            this.ciudad_empresa = this.empresa.ciudad_empresa;
            this.provincia_empresa = this.empresa.provincia_empresa;
            this.telefono_empresa = this.empresa.telefono_empresa;
            this.cif_empresa = this.empresa.cif_empresa;
            this.logo = this.empresa.logo;
            this.tipo_residencia_empresa = this.empresa.tipo_residencia_empresa;
            this.tipo_persona_empresa = this.empresa.tipo_persona_empresa;
            this.codigo_pais_empresa = this.empresa.codigo_pais_empresa;
            this.nombre_fisica_empresa = this.empresa.nombre_fisica_empresa;
            this.apellido1_fisica_empresa = this.empresa.apellido1_fisica_empresa;
            this.apellidos2_fisica_empresa = this.empresa.apellidos2_fisica_empresa;
            this.tipo_identificacion = this.empresa.tipo_identificacion;
            this.protecciondatos_empresa = this.empresa.protecciondatos_empresa;
            this.gestion_adjuntos_email = this.empresa.gestion_adjuntos_email;            
            this.$store.dispatch('cambiarCargando', false);
            this.datosempresa = this.$t('general.datosempresa');

        },
        async guardar(id) {
            /* put  modulos/pwgsapi/index.php/lineas-facturacion/:id   */
            const subidadatos = {nombre_empresa: this.nombre_empresa,nombre_comercial: this.nombre_comercial,direccion_empresa: this.direccion_empresa,codigo_postal_empresa: this.codigo_postal_empresa,ciudad_empresa: this.ciudad_empresa,provincia_empresa: this.provincia_empresa,telefono_empresa: this.telefono_empresa,cif_empresa: this.cif_empresa,logo: this.logo,tipo_residencia_empresa: this.tipo_residencia_empresa,tipo_persona_empresa: this.tipo_persona_empresa,codigo_pais_empresa: this.codigo_pais_empresa,nombre_fisica_empresa: this.nombre_fisica_empresa,apellido1_fisica_empresa: this.apellido1_fisica_empresa,apellidos2_fisica_empresa: this.apellidos2_fisica_empresa,tipo_identificacion: this.tipo_identificacion,protecciondatos_empresa: this.protecciondatos_empresa}
            const api = new PwgsApi;
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('empresas/' + this.id, subidadatos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$parent.$parent.obtenerEmpresa(id);
                    this.obtenerEmpresa(id);
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/lineas-facturacion/  */
                try {
                    let res;
                    res = await api.post('empresas/', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/empresas/' + res.id);
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
    },
    mounted() {
        
        this.obtenerEmpresa(this.id);
    },
    watch: {
        id() {
            this.obtenerEmpresa(this.id);
        }
    }
}
</script>
<style>
.custom-file-input ~ .custom-file-label::after {
    content: "Elegir";
}
</style>